export const COLUMNS = [
    {
        headerBody: 'Name',
        cellValuePath: 'formatted.nameLastFirst',
        width: '215px',
        sortable: true,
        bold: true,
    },
    {
        headerBody: 'DOB',
        cellValuePath: 'formatted.dobString',
        width: '130px',
    },
    {
        headerBody: 'Gender',
        cellValuePath: 'formatted.gender',
        width: '100px',
    },
    {
        headerBody: 'Address',
        cellValuePath: 'formatted.fullAddress',
    },
    {
        headerBody: 'Phone',
        cellValuePath: 'formatted.phone',
        width: '196px',
    },
    {
        headerBody: 'Check-In date',
        cellValuePath: 'formatted.checkInDate',
        width: '170px',
    },
];

const SEARCH_CONFIG = [
    { path: 'formatted.nameLastFirst' },
];

export const INITIAL_CONFIG = {
    sorting: {
        paths: 'formatted.nameLastFirst',
        descending: false,
    },
    filtering: {
        minCriteriaLength: 3,
        criteria: null,
        paths: SEARCH_CONFIG,
    },
    paging: {
        currentPage: 1,
        recordsPerPage: 20,
    },
};