import get from 'lodash/get';

function beforeRequest(actionContext) {
        actionContext.dispatch('CHECK_OUT_SEARCH_START');
}

function onSuccess(actionContext, { response, params = {} }) {
        actionContext.dispatch('CHECK_OUT_SEARCH_SUCCESS', {
            results: response.data,
            page: params.page,
            pageSize: params.pageSize,
        });
}

function onFailed(actionContext, { data }) {
        actionContext.dispatch('CHECK_OUT_SEARCH_FAILED', {
            failed: data,
            succeeded: [],
        });
}

export default config => ({
    serviceName: 'runSearchQuery',
    authenticate: true,
    config: {
        method: 'GET',
        authenticate: true,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: get(config, 'endpoints.emergencyCensus.checkIn.searchQuery'),
        // baseUrl: 'http://localhost:3000'
    },
    beforeRequest,
    onSuccess,
    onFailed,
    onError: onFailed,
    onTimeout: onFailed,
    onFatal: onFailed,
});
