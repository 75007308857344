import React from 'react';
import applyFluxibleContext from '@audacious/web-common/fluxible/applyFluxibleContext';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    PageContainer,
    PageTitle,
} from '@audacious/components/components/Page';
import { faExclamationCircle } from '@audacious/icons/regular/faExclamationCircle';
import { faArrowLeft } from '@audacious/icons/regular/faArrowLeft';
import AlertMessage from '@audacious/components/components/AlertMessage';
import LinkButton from '@audacious/components/components/LinkButton';
import IconButton from '@audacious/components/components/IconButton';
import BasePage from '../../common/base-page';
import showNotification from '../../../actions/show-notification';
import { clearStores } from '../../../actions/home-actions';

class CheckInError extends React.Component {
    constructor(props) {
        super(props);

        this.handleGotoHome = this.handleGotoHome.bind(this);
    }

    componentDidMount() {
        const {
            fluxibleContext: { executeAction },
        } = this.props;

        executeAction(showNotification, {
            heading: 'Check-In Unsuccessful',
            message:
                'We are unable to check-in at this time. Please try again.',
            color: 'danger',
        });
    }

    handleGotoHome() {
        const {
            history,
            fluxibleContext: { executeAction },
        } = this.props;

        executeAction(clearStores);

        history.push('home');
    }

    render() {
        return (
            <BasePage
                id="check-in-page"
                pageName="Check In"
                onGoto={this.handleGotoHome}
            >
                <PageTitle pageName="Check-In" />
                <PageContainer asCard allowScroll>
                    <AlertMessage
                        color="danger"
                        icon={faExclamationCircle}
                        message="We are unable to check-in at this time. Please try again later."
                        size="xs"
                        className="ec-error-alert"
                    />
                    <div className="error-back">
                        <IconButton
                            icon={faArrowLeft}
                            size="lg"
                            color="primary"
                            className="error-caret"
                            onClick={this.handleGotoHome}
                        />
                        <LinkButton
                            variant="outline"
                            color="primary"
                            className="error-link"
                            onClick={this.handleGotoHome}
                        >
                            Back to Emergency Census
                        </LinkButton>
                    </div>
                </PageContainer>
            </BasePage>
        );
    }
}

CheckInError.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func,
    }).isRequired,
    fluxibleContext: PropTypes.shape({
        executeAction: PropTypes.func,
    }).isRequired,
};

export default withRouter(applyFluxibleContext(CheckInError));
