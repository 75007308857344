import React from 'react';
import applyFluxibleContext from '@audacious/web-common/fluxible/applyFluxibleContext';
import { connectToStores } from 'fluxible-addons-react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import { withRouter } from 'react-router-dom';
import {
    PageContainer,
    PageTitle,
} from '@audacious/components/components/Page';
import CheckOutStart from './check-out-start';
import CheckOutDestination from './check-out-destination';
import CheckOutReview from './check-out-review';
import { startCheckOut } from '../../../actions/check-out-actions';
import searchDataPropType from './search-data-prop-type';
import BasePage from '../../common/base-page';
import selectedPatientsPropType from './selected-patients-prop-type';
import './check-out.scss';

class CheckOut extends React.Component {
    componentDidMount() {
        const {
            tenantId,
            fluxibleContext: {
                executeAction,
                service: { retrieveEpraPreferences },
            },
        } = this.props;

        retrieveEpraPreferences({
            options: { tenantId },
        });

        executeAction(startCheckOut);
    }

    renderSteps() {
        const {
            step,
            searchData,
            selectedPatients,
            isCommitting,
            isErrored,
            tenantId,
            userName,
            allowPrint,
            sortParams,
        } = this.props;

        if (step === 'review') {
            return (
                <CheckOutReview
                    selectedPatients={selectedPatients}
                    isCommitting={isCommitting}
                    isErrored={isErrored}
                />
            );
        }

        if (step === 'destination') {
            return <CheckOutDestination selectedPatients={selectedPatients} />;
        }

        return (
            <CheckOutStart
                searchData={searchData}
                initialSelected={selectedPatients}
                tenantId={tenantId}
                userName={userName}
                allowPrint={allowPrint}
                sortParams={sortParams}
            />
        );
    }

    render() {
        return (
            <BasePage
                id="check-out-page"
                pageName="Check Out"
            >
                <div>
                    <PageTitle pageName="Check-Out" />
                    <PageContainer asCard allowScroll>
                        {this.renderSteps()}
                    </PageContainer>
                </div>
            </BasePage>
        );
    }
}

CheckOut.propTypes = {
    step: PropTypes.string,
    searchData: searchDataPropType.isRequired,
    selectedPatients: selectedPatientsPropType.isRequired,
    isCommitting: PropTypes.bool.isRequired,
    isErrored: PropTypes.bool.isRequired,
    tenantId: PropTypes.string.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func,
    }).isRequired,
    userName: PropTypes.string.isRequired,
    allowPrint: PropTypes.bool.isRequired,
    fluxibleContext: PropTypes.shape({
        executeAction: PropTypes.func,
        service: PropTypes.shape({
            retrieveEpraPreferences: PropTypes.func,
        }),
    }).isRequired,
    sortParams: PropTypes.shape({
        sortPath: PropTypes.string,
        descending: PropTypes.bool,
    }).isRequired,
};

CheckOut.defaultProps = {
    step: null,
};

const BoundComponent = connectToStores(
    applyFluxibleContext(CheckOut),
    ['CheckOutWorkflowStore', 'Session', 'EpraPreferencesStore'],
    context => {
        const checkOutStore = context.getStore('CheckOutWorkflowStore');
        const sessionStore = context.getStore('Session');

        const step = checkOutStore.getWorkflowStep();
        const searchData = checkOutStore.getSearchData();
        const selectedPatients = checkOutStore.getSelectedPatients();
        const isCommitting = checkOutStore.isCommitting();
        const tenantId = sessionStore.getTenant().getId();
        const user = sessionStore.getUser();

        const userName = isNil(user) ? '' : user.getFullName();

        const epraPreferencesState = context
            .getStore('EpraPreferencesStore')
            .getState();

        const allowPrint = get(
            epraPreferencesState,
            'preferences.allowPrint',
            false,
        );

        const sortParams = checkOutStore.getSortParams();

        return {
            step,
            searchData,
            selectedPatients,
            isCommitting,
            isErrored: !isNil(checkOutStore.getError()),
            tenantId,
            userName,
            allowPrint,
            sortParams,
        };
    },
);

export default withRouter(BoundComponent);
