/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-curly-brace-presence */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import dateFormatter from '@audacious/web-common/formatters/dateFormatter';
import IconButton from '@audacious/components/components/IconButton';
import { faPrint } from '@audacious/icons/solid/faPrint';
import { faArrowDownToLine } from '@audacious/icons/regular/faArrowDownToLine';
import {
    RecordsPerPageSelector,
    PageNavigation,
} from '@audacious/components/components/Pagination';
import exportPatientsCsv from '../../../../common/util/export-patients-csv';
import printPatients from '../../../../common/util/print-patients';
import { PAGE_SIZE_OPTIONS } from '../../../../constants/paging-constants';

function TopPaginationControls(props) {
	const {
		currentPage,
		recordsPerPage,
		total,
		allowPrint,
		userName,
		facilityName,
		locationName,
		allItems,
		onChangeRecordsPerPage,
		handleGotoPage,
	} = props;

	const handleExportClick = useCallback(() => {
		const date = dateFormatter(new Date().toString(), {
            outFormat: 'MM/DD/YYYY h:mm:ss a',
        });

		exportPatientsCsv({
			patients: allItems,
			userName,
			facilityName,
			locationName,
			date,
		});
	});

	const handlePrintClick = useCallback(() => {
		const date = dateFormatter(new Date().toString(), {
            outFormat: 'MM/DD/YYYY h:mm:ss a',
        });

        printPatients({
			patients: allItems,
			userName,
			facilityName,
			locationName,
			date,
		});
	});

	const exportButtonsRender = allowPrint ? (
		<>
			<IconButton
				id="allItems-export-button"
				className="export-button"
				icon={faArrowDownToLine}
				size="sm"
				onClick={handleExportClick}
				aria-label="Export CSV"
			/>
			<IconButton
				id="allItems-print-button"
				className="export-button"
				icon={faPrint}
				size="sm"
				onClick={handlePrintClick}
				aria-label="Print"
			/>
		</>
	) : null;

	return (
		<>
			<RecordsPerPageSelector
				numRecords={total}
				currentPage={currentPage}
				recordsPerPage={recordsPerPage}
				recordsPerPageOptions={PAGE_SIZE_OPTIONS}
				onChangeRecordsPerPage={onChangeRecordsPerPage}
			/>
			<PageNavigation
				numRecords={total}
				currentPage={currentPage}
				recordsPerPage={recordsPerPage}
				onGotoPage={handleGotoPage}
				firstAndLastNav
			/>
			{exportButtonsRender}
		</>
	);
}

TopPaginationControls.propTypes = {
	currentPage: PropTypes.number.isRequired,
	recordsPerPage: PropTypes.number.isRequired,
	total: PropTypes.number.isRequired,
	allowPrint: PropTypes.bool.isRequired,
	userName: PropTypes.string.isRequired,
    locationName: PropTypes.string.isRequired,
    facilityName: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    allItems: PropTypes.any,
	onChangeRecordsPerPage: PropTypes.func.isRequired,
	handleGotoPage: PropTypes.func.isRequired,
};

TopPaginationControls.defaultProps = {
	allItems: null,
};

export default TopPaginationControls;