import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@audacious/components/components/Chip';

function LoadingChip(props) {
	const { running } = props;

	if (!running) {
		return null;
	}

	return (
		<Chip
			className="loading-alert"
			color="grey"
			shade="darker"
			variant="pill"
			size="xl"
		>
			Loading...
		</Chip>
	);
}

LoadingChip.propTypes = {
	running: PropTypes.bool.isRequired,
};

export default LoadingChip;