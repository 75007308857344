/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-curly-brace-presence */
import React, { useCallback, useEffect, useState } from 'react';
import { connectToStores, useFluxible } from 'fluxible-addons-react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Row, Column } from '@audacious/components/components/Grid';
import useTableManager from '@audacious/components/hooks/useTableManager';
import Button from '@audacious/components/components/Button';
import TextInput from '@audacious/components/components/TextInput';
import { faMagnifyingGlass } from '@audacious/icons/regular/faMagnifyingGlass';
import { PageContainerGroup } from '@audacious/components/components/Page';
import SelectedOverview from '@audacious/components/components/SelectedOverview';
import { Text } from '@audacious/components/components/Typography';
import isNil from 'lodash/isNil';
import keys from 'lodash/keys';
import reduce from 'lodash/reduce';
import mapValues from 'lodash/mapValues';
import cloneDeep from 'lodash/cloneDeep';
import clone from 'lodash/clone';
import {
    startCheckOut,
    startDestination,
} from '../../../../actions/check-out-actions';
import searchDataPropType from '../search-data-prop-type';
import selectedPatientsPropType from '../selected-patients-prop-type';
import { clearStores } from '../../../../actions/home-actions';
import runSearchQuery from '../../../../actions/run-search-query';
import TopPaginationControls from './top-pagination-controls';
import BottomPaginationControls from './bottom-pagination-controls';
import CheckOutStartTable from './check-out-start-table';
import LoadingChip from './loading-chip';
import { COLUMNS, INITIAL_CONFIG } from './check-out-constants';

function CheckOutStart(props) {
    const {
        initialSelected,
        tenantId,
        facilityId,
        searchData: {
            results,
            running,
        },
        history,
        allowPrint,
        userName,
        facilityName,
        locationName,
    } = props;

    const { executeAction } = useFluxible();

    const [selected, setSelected] = useState(initialSelected);
    
    useEffect(() => {
        executeAction(startCheckOut);

        if (facilityId) {
            executeAction(runSearchQuery, {
                options: { tenantId, facilityId },
            });
        }
    }, []);
    
    const [processedData, config, setCallbacks] = useTableManager(
        results,
        COLUMNS,
        INITIAL_CONFIG,
    );

    const {
        filteredItems,
        items,
        total
    } = processedData;

    const handleSearchChange = useCallback(value => {
        setCallbacks.setFilteringCriteria(value);
        setCallbacks.setCurrentPage(1);
    }, [setCallbacks]);

    const handleSearchClear = useCallback(() => {
        setCallbacks.setFilteringCriteria(null);
        setCallbacks.setCurrentPage(1);
    }, [setCallbacks]);

    const handleNextClick = useCallback(() => {
        const selectedPatients = mapValues(selected, item => {
            const copy = cloneDeep(item);

            copy.destination = {
                type: 'home',
            };

            return copy;
        });

        executeAction(startDestination, selectedPatients);
    });

    const handleCancelClick = useCallback(() => {
        executeAction(clearStores);
        history.push('/home');
    });

    const handleHeaderSelect = useCallback((selectedItems, checkState) => {
        const copy = reduce(filteredItems, (acc, item) => {
            if (checkState) {
                acc[item.id] = item;
            } else {
                delete acc[item.id];
            }

            return acc;
        }, clone(selected));

        setSelected(copy);
    });

    const handleRowSelect = useCallback((item) => {
        const copy = clone(selected);

        if (!isNil(copy[item.id])) {
            delete copy[item.id];
        } else {
            copy[item.id] = item;
        }

        setSelected(copy);
    });

    const selectedCount = keys(selected).length;

    let selectedElement = null;

    if (selectedCount > 0) {
        selectedElement = (
            <SelectedOverview
                className="selected-patients"
                buttonText="Next"
                id="selected-count"
                numSelected={selectedCount}
                onButtonClick={handleNextClick}
            />
        );
    }

    return (
        <div className="check-out-start">
            <PageContainerGroup>
                <Row gutter="16">
                    <Column width="fill">
                        <Text size="xl" weight="bold">
                            Select to Check Out
                        </Text>
                    </Column>
                </Row>
                <Row gutter="16" enableAfter>
                    <Column width="3">
                        <TextInput
                            id="search-term"
                            aria-label="Search Term"
                            value={config.filtering.criteria}
                            onChange={handleSearchChange}
                            leftIcon={{
                                icon: faMagnifyingGlass,
                            }}
                            size="sm"
                            width="xsm"
                            focusOnMount
                            onClear={handleSearchClear}
                            disabled={running}
                        />
                    </Column>
                    <Column
                        width="fill"
                        className="top-pagination-controls-column"
                    >
                        <TopPaginationControls
                            total={total}
                            allowPrint={allowPrint}
                            userName={userName}
                            facilityName={facilityName}
                            locationName={locationName}
                            allItems={filteredItems}
                            onChangeRecordsPerPage={setCallbacks.setRecordsPerPage}
                            handleGotoPage={setCallbacks.setCurrentPage}
                            currentPage={config.paging.currentPage}
                            recordsPerPage={config.paging.recordsPerPage}
                        />
                    </Column>
                </Row>
                <LoadingChip running={running} />
            </PageContainerGroup>

            <CheckOutStartTable
                selected={selected}
                columns={COLUMNS}
                results={items}
                filteredItems={filteredItems}
                onHeaderSelect={handleHeaderSelect}
                onRowSelect={handleRowSelect}
                sortPath={config.sorting.paths}
                sortDescending={config.sorting.descending}
                onSortChange={setCallbacks.setSortParams}
            />
            {selectedElement}

            <PageContainerGroup>
                <Row gutter="16">
                    <Column
                        width="fill"
                        className="botton-pagination-controls-column"
                    >
                        <BottomPaginationControls
                            currentPage={config.paging.currentPage}
                            recordsPerPage={config.paging.recordsPerPage}
                            total={total}
                            handleGotoPage={setCallbacks.setCurrentPage}
                            handleChangePageSize={setCallbacks.setRecordsPerPage}
                        />
                    </Column>
                </Row>
                <Row gutter="16">
                    <Column width="fill" />
                    <Column width="content">
                        <Button
                            id="checkOutCancel"
                            color="secondary"
                            variant="opaque"
                            onClick={handleCancelClick}
                        >
                            Cancel
                        </Button>
                    </Column>
                </Row>
            </PageContainerGroup>
        </div>
    );
}

CheckOutStart.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func,
    }).isRequired,
    searchData: searchDataPropType.isRequired,
    initialSelected: selectedPatientsPropType,
    tenantId: PropTypes.string.isRequired,
    facilityId: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
    locationName: PropTypes.string.isRequired,
    facilityName: PropTypes.string.isRequired,
    allowPrint: PropTypes.bool.isRequired,
    sortParams: PropTypes.shape({
        sortPath: PropTypes.string,
        descending: PropTypes.bool,
    }).isRequired,
};

CheckOutStart.defaultProps = {
    initialSelected: null,
};

const BoundComponent = connectToStores(
    CheckOutStart,
    ['Session'],
    context => {
        const sessionStore = context.getStore('Session');

        const facility = sessionStore.getFacility();
        const facilityId = facility ? facility.getId() : '';
        const facilityName = isNil(facility) ? '' : facility.getName();
        const locationName = isNil(facility) ? '' : facility.getLocationName();

        return {
            facilityId,
            facilityName,
            locationName,
        };
    },
);

export default withRouter(BoundComponent);
