import PropTypes from 'prop-types';

const selectedPatientsPropType = PropTypes.objectOf(
    PropTypes.shape({
        // eslint-disable-next-line react/forbid-prop-types
        patient: PropTypes.object,
        formatted: PropTypes.shape({
            nameLastFirst: PropTypes.string,
            name: PropTypes.string,
            fullAddress: PropTypes.string,
            address: PropTypes.shape({
                address1: PropTypes.string,
                address2: PropTypes.string,
                city: PropTypes.string,
                state: PropTypes.string,
                zipCode: PropTypes.string,
            }),
            dob: PropTypes.string,
            phone: PropTypes.string,
            gender: PropTypes.string,
        }),
        destination: PropTypes.shape({
            type: PropTypes.string,
            deceased: PropTypes.objectOf({
                day: PropTypes.string,
                month: PropTypes.string,
                year: PropTypes.string,
            }),
            transferredTo: PropTypes.string,
            checkOutLocation: PropTypes.string,
        }),
    }),
);

export default selectedPatientsPropType;
