import React from 'react';
import PropTypes from 'prop-types';
import { SimpleTable } from '@audacious/components/components/Table';
import isNil from 'lodash/isNil';
import find from 'lodash/find';
import keys from 'lodash/keys';
import { COLUMNS } from './check-out-constants';
import selectedPatientsPropType from '../selected-patients-prop-type';

function CheckOutStartTable(props) {
	const {
		selected,
		results,
		onHeaderSelect,
		onRowSelect,
		sortPath,
		sortDescending,
		onSortChange,
		filteredItems,
	} = props;

	if (isNil(results)) {
		return null;
	}

	if (results.length <= 0) {
		return (
			<>
				<h2 className="check-out-no-results-header">
					No Results Found
				</h2>
				<span className="check-out-no-results-message">
					Check to see if the person’s name is spelled correctly
					and try again.
				</span>
			</>
		);
	}

	return (
		<SimpleTable
			items={results}
			columns={COLUMNS}
			alwaysShowHeaders
			minWidth={1048}
			headerSelectable
			selectable
			stickyColumns={2}
			onHeaderSelect={onHeaderSelect}
			onRowSelect={onRowSelect}
			isRowSelected={item => !isNil(selected[item.id])}
			isHeaderSelected={() => {
				if (isNil(filteredItems) || filteredItems.length <= 0) {
					return false;
				}

				if (filteredItems.length > keys(selected).length) {
					return false;
				}

				// Find an item that isn't selected
				const found = find(filteredItems, (item) => isNil(selected[item.id]));

				// If not found then all are selected
				return isNil(found);
			}}
			sortPath={sortPath}
			sortDescending={sortDescending}
			onSortChange={onSortChange}
		/>
	);
}

CheckOutStartTable.propTypes = {
	selected: selectedPatientsPropType.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	results: PropTypes.array,
	onHeaderSelect: PropTypes.func.isRequired,
	onRowSelect: PropTypes.func.isRequired,
	sortPath: PropTypes.oneOfType([PropTypes.string,PropTypes.arrayOf(PropTypes.string)]),
	sortDescending: PropTypes.bool.isRequired,
	onSortChange: PropTypes.func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	filteredItems: PropTypes.array,
};

CheckOutStartTable.defaultProps = {
	results: null,
	sortPath: null,
	filteredItems: null,
};

export default CheckOutStartTable