import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { AuthenticatedPage } from '@audacious/client';
import FacilitySelection, {
    FacilityHeaderFeature,
} from '@audacious/pulse-facility-selection';

class BasePage extends PureComponent {
    render() {
        const {
            id,
            className,
            pageName,
            onGoto,
            children,
            hideTitle,
        } = this.props;

        return (
            <AuthenticatedPage
                id={id}
                className={className}
                pageName={pageName}
                onGoto={onGoto}
                additionalFeatures={[
                    <FacilityHeaderFeature key="FacilityHeaderFeature" />,
                ]}
                hideTitle={hideTitle}
            >
                <FacilitySelection>{children}</FacilitySelection>;
            </AuthenticatedPage>
        );
    }
}

BasePage.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
    ]),
    pageName: PropTypes.string.isRequired,
    onGoto: PropTypes.func,
    hideTitle: PropTypes.bool,
};

BasePage.defaultProps = {
    id: null,
    className: null,
    onGoto: null,
    children: null,
    hideTitle: false,
};

export default BasePage;
