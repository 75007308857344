/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-curly-brace-presence */
import React from 'react';
import PropTypes from 'prop-types';
import {
    RecordsPerPageSelector,
    PageNavigation,
} from '@audacious/components/components/Pagination';
import { PAGE_SIZE_OPTIONS } from '../../../../constants/paging-constants';

function BottomPaginationControls(props) {
	const {
		currentPage,
		recordsPerPage,
		total,
		handleGotoPage,
		handleChangePageSize,
	} = props;

        if (
            total <= 0
        ) {
            return null;
        }

        return (
            <>
                <PageNavigation
                    numRecords={total}
                    currentPage={currentPage}
                    recordsPerPage={recordsPerPage}
                    onGotoPage={handleGotoPage}
                    numJumpPages={7}
                    jumpToPageNav
                    firstAndLastNav
                />
                <RecordsPerPageSelector
                    numRecords={total}
                    currentPage={currentPage}
                    recordsPerPage={recordsPerPage}
                    recordsPerPageOptions={PAGE_SIZE_OPTIONS}
                    onChangeRecordsPerPage={handleChangePageSize}
                />
            </>
        );
}

BottomPaginationControls.propTypes = {
	currentPage: PropTypes.number.isRequired,
	recordsPerPage: PropTypes.number.isRequired,
	total: PropTypes.number.isRequired,
	handleChangePageSize: PropTypes.func.isRequired,
	handleGotoPage: PropTypes.func.isRequired,
};

export default BottomPaginationControls;